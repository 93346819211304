import React, { useState, useEffect } from 'react';
import Navbar from '../components/NavBar';
import '../styles/Demo.css';

const Demo = () => {
    const [activeView, setActiveView] = useState('');

    const prompts = [
        {
            title: "Instructions for generating regexes for AT&T (AS7018)",
            content: `Your task is to generate a regular expression that extracts geographic information from a given class of RDNS records.  You will be given examples.

Instructions:
1. For the class provided, analyze the example record(s) to identify patterns in the encoding of geographic information such as city, state, region, or country.
2. Provide a concise yet detailed description of the common pattern among the records. The description should include:
   - If there are geohints within the record
   - The position of the geohint within the PTR record and what delimeters or characters surround it.
   - An **example pattern** format that can be easily translated into regular expressions.
3. Generate a regular expression that captures the geographic information in the class.

Output Structure:
1. Format your response as JSON with the a description and regex key. Enclose the JSON in \`\`\`json and \`\`\` so we can extract it.
2. Each key should include:
   - regex: A regular expression that captures the geographic hint (s) in the record.
   - description: A concise yet detailed description of the pattern.
   - usable: Whether the records belonging to this class contain any geohints; Top Level Domains alone do not count as country level information.
   `
        },
        {
            title: "Classification prompt used by The Aleph",
            content: `You are tasked with examining a list of RDNS records and grouping them based on the pattern of how and where they encode geographic and other types of information. Create groups only if there are records that fit the pattern criteria.

Instructions for Grouping:
1. Carefully analyze the RDNS records to identify patterns in the encoding of geographic information such as city, state, region, country, IATA airport codes, or other specific data.
2. Create groups based on these patterns only if multiple records within a domain share the same type of encoded information and similar encoding positions. Do not force a group formation if the pattern is not clearly present or is only represented by one record unless it is a unique and distinguishable pattern.
3. For each identified group, assign a concise name that reflects its defining characteristics, such as the provider name, network type, or encoding style.
4. **Group RDNS records into broader classes** based on high-level characteristics such as the provider name or network type. Do not create separate classes for individual cities or regions within the same provider/network type.
5. Create a new group for each type of record that would require a different regular expression to capture the geographic information. This should be based firstly on the top level domain name and divided further if needed.

Output Structure:
1. Format your response as JSON with each category as a key only if the group has been formed based on clear, repeated patterns. Enclose it in \`\`\`json and \`\`\` to denote the JSON.
2. Each key should include:
   - examples: A list containing up to 5 representative records that exemplify the group.`
        },
        {
            title: "Prompt used by the Aleph to generate hint mappings.",
            content: `You are an expert in Computer Networks. Network operators often encode geographic information within RDNS records as abbreviated or full city names, state names, and IATA or ICAO airport codes. Your task is to examine a list of RDNS records and map each geographic hint found within the records to the actual city, state, and country it refers to. The keys in the mapping should be the exact strings extracted from the records by a regex, representing geographic hints.

Instructions:
- Return your answer in JSON format directly suitable. Enclose the JSON in \`\`\`json and \`\`\` for easy parsing.
- The JSON should include keys for 'city', 'state', 'region', and 'country'. Use two-letter abbreviations for the state, region, and country codes. If a hint does not provide information for one of these categories, return an empty string for that key.
- Ensure the hints extracted represent something that would be extracted by a regex

Map each record to a class, and use the regular expression for that class to create a mapping for the hints. 

Guidelines:
- If a record could belong to more than one class, use the class that encodes finer granularity information to extract the hint.
- Apply the \`Generated Regular Expression\` to each RDNS record based on the class it belongs to extract the relevant geographic hints.
- Use the exact extracted hint strings as keys in the mapping.
- Map each hint to the actual 'city', 'state', 'region', and 'country' where possible, and use empty strings for keys where information is unavailable.
- Do not exceed your output limit`},
        // More listings can be added here following the same pattern
    ];

    const inputs = [
        {
            title : `PTR Records From AT\&T Used For Grouping`,
            content: `IP,PTR
99.170.164.205,99-170-164-205.lightspeed.tukrga.sbcglobal.net
107.194.77.243,107-194-77-243.lightspeed.austtx.sbcglobal.net
99.163.197.39,adsl-99-163-197-39.dsl.wac2tx.sbcglobal.net
208.191.10.108,adsl-208-191-10-108.dsl.okcyok.swbell.net
99.75.30.16,adsl-99-75-30-16.dsl.rcfril.sbcglobal.net
99.36.211.162,adsl-99-36-211-162.dsl.skt2ca.sbcglobal.net
162.193.172.90,162-193-172-90.lightspeed.miamfl.sbcglobal.net
104.183.175.2,104-183-175-2.lightspeed.okcbok.sbcglobal.net
104.187.221.175,104-187-221-175.lightspeed.toldoh.sbcglobal.net
70.224.64.70,70-224-64-70.lightspeed.irvnca.sbcglobal.net
23.123.140.46,23-123-140-46.lightspeed.sntcca.sbcglobal.net
99.152.50.15,adsl-99-152-50-15.dsl.elp2tx.sbcglobal.net
74.237.84.231,adsl-074-237-084-231.sip.mia.bellsouth.net
68.90.197.179,adsl-68-90-197-179.dsl.rcsntx.swbell.net
108.86.114.172,adsl-108-86-114-172.dsl.stl2mo.sbcglobal.net
104.184.51.205,104-184-51-205.lightspeed.hstntx.sbcglobal.net
99.29.170.93,99-29-170-93.lightspeed.clmboh.sbcglobal.net
75.54.16.132,75-54-16-132.lightspeed.oklyca.sbcglobal.net
104.189.189.15,104-189-189-15.lightspeed.rcsntx.sbcglobal.net
108.77.18.58,108-77-18-58.lightspeed.sndgca.sbcglobal.net
207.252.227.38,usr38.clearsail.net
45.25.167.108,45-25-167-108.lightspeed.cicril.sbcglobal.net
172.8.238.211,172-8-238-211.lightspeed.sntcca.sbcglobal.net
68.79.198.96,68-79-198-96.lightspeed.rcsntx.sbcglobal.net
76.211.181.208,76-211-181-208.lightspeed.snantx.sbcglobal.net
108.86.74.130,108-86-74-130.lightspeed.rcsntx.sbcglobal.net
45.22.135.78,45-22-135-78.lightspeed.dctril.sbcglobal.net
99.115.153.251,99-115-153-251.lightspeed.btrgla.sbcglobal.net
107.198.54.87,107-198-54-87.lightspeed.rcsntx.sbcglobal.net
75.8.70.118,adsl-75-8-70-118.dsl.sfldmi.sbcglobal.net
104.191.48.190,104-191-48-190.lightspeed.tukrga.sbcglobal.net
99.46.156.100,99-46-156-100.lightspeed.rlghnc.sbcglobal.net
99.94.185.62,adsl-99-94-185-62.dsl.sfldmi.sbcglobal.net
108.244.51.45,108-244-51-45.lightspeed.frsnca.sbcglobal.net
67.122.3.2,adsl-67-122-3-2.dsl.lsan03.pacbell.net
108.217.237.71,108-217-237-71.lightspeed.wlfrct.sbcglobal.net
76.247.224.233,adsl-76-247-224-233.dsl.pltn13.sbcglobal.net
104.11.137.202,104-11-137-202.lightspeed.irvnca.sbcglobal.net
76.243.157.95,adsl-76-243-157-95.dsl.okcyok.sbcglobal.net
104.183.214.4,104-183-214-4.lightspeed.frokca.sbcglobal.net
162.233.240.171,adsl-162-233-240-171.lightspeed.irvnca.sbcglobal.net
71.139.168.6,ppp-71-139-168-6.dsl.snfc21.pacbell.net
104.58.3.208,104-58-3-208.lightspeed.hstntx.sbcglobal.net
71.128.154.120,adsl-71-128-154-120.dsl.scrm01.pacbell.net
104.181.39.145,104-181-39-145.lightspeed.miamfl.sbcglobal.net
99.6.217.156,99-6-217-156.lightspeed.milwwi.sbcglobal.net
99.184.143.123,adsl-99-184-143-123.dsl.stlsmo.sbcglobal.net
99.71.220.212,99-71-220-212.lightspeed.sndgca.sbcglobal.net
75.32.10.237,75-32-10-237.lightspeed.dllstx.sbcglobal.net
75.52.167.51,75-52-167-51.lightspeed.moblal.sbcglobal.net
162.195.150.130,162-195-150-130.lightspeed.snantx.sbcglobal.net
99.165.29.10,adsl-99-165-29-10.dsl.pltn13.sbcglobal.net
99.58.251.167,adsl-99-58-251-167.dsl.tul2ok.sbcglobal.net
76.242.70.73,76-242-70-73.lightspeed.dllstx.sbcglobal.net
69.210.207.232,adsl-69-210-207-232.dsl.klmzmi.ameritech.net
108.195.204.188,108-195-204-188.lightspeed.frokca.sbcglobal.net
76.222.92.104,adsl-76-222-92-104.dsl.pltn13.sbcglobal.net
23.121.134.63,23-121-134-63.lightspeed.jcsnms.sbcglobal.net
108.222.89.102,108-222-89-102.lightspeed.toldoh.sbcglobal.net
72.151.67.111,adsl-072-151-067-111.sip.clt.bellsouth.net
72.149.62.224,adsl-072-149-062-224.sip.bct.bellsouth.net
162.198.92.252,162-198-92-252.lightspeed.irvnca.sbcglobal.net
45.23.182.242,45-23-182-242.lightspeed.rcsntx.sbcglobal.net
108.253.19.81,108-253-19-81.lightspeed.mmphtn.sbcglobal.net
75.49.173.65,75-49-173-65.lightspeed.nsvltn.sbcglobal.net
75.28.49.230,adsl-75-28-49-230.dsl.chi2ca.sbcglobal.net
76.200.200.24,76-200-200-24.lightspeed.oshkwi.sbcglobal.net
75.32.198.254,75-32-198-254.lightspeed.ftwotx.sbcglobal.net
99.41.98.173,99-41-98-173.lightspeed.tulsok.sbcglobal.net
69.211.206.104,adsl-69-211-206-104.dsl.ipltin.ameritech.net
99.87.195.108,99-87-195-108.lightspeed.irvnca.sbcglobal.net
99.162.165.142,adsl-99-162-165-142.dsl.wacotx.sbcglobal.net
75.53.235.76,75-53-235-76.lightspeed.gdrpmi.sbcglobal.net
75.43.236.184,adsl-75-43-236-184.dsl.lsan03.sbcglobal.net
76.243.78.104,adsl-76-243-78-104.dsl.crchtx.sbcglobal.net
64.165.26.58,adsl-64-165-26-58.dsl.sndg02.pacbell.net
75.13.64.39,adsl-75-13-64-39.dsl.wlfrct.sbcglobal.net
23.112.64.174,23-112-64-174.lightspeed.nworla.sbcglobal.net
108.90.122.158,108-90-122-158.lightspeed.irvnca.sbcglobal.net
12.230.195.202,ip-12-230-195-CA.aradigm.com
67.37.65.44,ppp-67-37-65-44.dialup.sfldmi.ameritech.net
76.225.54.166,76-225-54-166.lightspeed.bkfdca.sbcglobal.net
162.207.90.236,162-207-90-236.lightspeed.irvnca.sbcglobal.net
99.102.201.97,adsl-99-102-201-97.dsl.milwwi.sbcglobal.net
76.214.40.3,adsl-76-214-40-3.dsl.wlfrct.sbcglobal.net
108.223.100.81,108-223-100-81.lightspeed.jcvlfl.sbcglobal.net
45.24.41.123,45-24-41-123.lightspeed.hstntx.sbcglobal.net
99.69.104.68,99-69-104-68.lightspeed.tulsok.sbcglobal.net
108.227.76.30,108-227-76-30.lightspeed.moblal.sbcglobal.net
108.233.201.222,108-233-201-222.lightspeed.sntcca.sbcglobal.net
99.98.224.208,99-98-224-208.lightspeed.mssnks.sbcglobal.net
65.82.248.168,adsl-065-082-248-168.sip.sdf.bellsouth.net
63.201.3.126,adsl-63-201-3-126.dsl.snlo01.pacbell.net
70.228.60.46,adsl-70-228-60-46.dsl.sfldmi.ameritech.net
69.219.222.66,adsl-69-219-222-66.dsl.chcgil.ameritech.net
74.236.1.94,adsl-074-236-001-094.sip.mia.bellsouth.net
45.30.147.232,45-30-147-232.lightspeed.dybhfl.sbcglobal.net
23.121.252.158,23-121-252-158.lightspeed.wepbfl.sbcglobal.net
99.183.219.26,adsl-99-183-219-26.dsl.sndg02.sbcglobal.net
162.192.143.43,162-192-143-43.lightspeed.irvnca.sbcglobal.net
99.170.170.212,99-170-170-212.lightspeed.tukrga.sbcglobal.net
207.215.154.75,207-215-154-75.cabrillocu.com
76.252.83.94,adsl-76-252-83-94.dsl.chcgil.sbcglobal.net
108.240.127.41,108-240-127-41.lightspeed.cicril.sbcglobal.net
108.210.205.217,108-210-205-217.lightspeed.sntcca.sbcglobal.net
23.124.89.190,23-124-89-190.lightspeed.hstntx.sbcglobal.net
108.80.227.194,adsl-108-80-227-194.dsl.pltn13.sbcglobal.net
104.57.10.165,104-57-10-165.lightspeed.clmboh.sbcglobal.net
68.209.39.163,adsl-068-209-039-163.sip.asm.bellsouth.net
69.239.246.190,adsl-69-239-246-190.dsl.irvnca.pacbell.net
99.98.64.64,99-98-64-64.lightspeed.ltrkar.sbcglobal.net
70.137.140.148,adsl-70-137-140-148.dsl.snfc21.sbcglobal.net
75.28.176.204,75-28-176-204.lightspeed.evtnil.sbcglobal.net
69.224.145.130,adsl-69-224-145-130.dsl.scrm01.pacbell.net
162.236.50.34,162-236-50-34.dsl.pltn13.sbcglobal.net
99.12.207.28,adsl-99-12-207-28.dsl.scrm01.sbcglobal.net
107.198.97.11,107-198-97-11.lightspeed.cicril.sbcglobal.net
63.206.114.214,adsl-63-206-114-214.dsl.snfc21.pacbell.net
12.64.200.158,slip-12-64-200-158.mis.prserv.net
76.198.75.87,adsl-76-198-75-87.dsl.skt2ca.sbcglobal.net
99.8.85.203,99-8-85-203.lightspeed.livnmi.sbcglobal.net
108.197.87.167,108-197-87-167.lightspeed.mssnks.sbcglobal.net
99.115.166.223,99-115-166-223.lightspeed.chrlnc.sbcglobal.net
107.219.121.159,107-219-121-159.lightspeed.cicril.sbcglobal.net
108.248.219.25,108-248-219-25.lightspeed.chrlnc.sbcglobal.net
99.63.100.244,99-63-100-244.lightspeed.bcvloh.sbcglobal.net
69.229.70.141,adsl-69-229-70-141.dsl.sndg02.sbcglobal.net
76.255.3.100,76-255-3-100.lightspeed.wchtks.sbcglobal.net
99.77.117.143,99-77-117-143.lightspeed.tukrga.sbcglobal.net
99.164.160.133,99-164-160-133.lightspeed.tpkaks.sbcglobal.net
68.248.190.168,68-248-190-168.lightspeed.livnmi.sbcglobal.net
70.226.99.195,70-226-99-195.lightspeed.lbcktx.sbcglobal.net
75.18.204.139,adsl-75-18-204-139.dsl.pltn13.sbcglobal.net
99.40.217.251,adsl-99-40-217-251.dsl.chcgil.sbcglobal.net
107.203.237.223,107-203-237-223.lightspeed.iplsin.sbcglobal.net
76.250.60.239,76-250-60-239.lightspeed.lnngmi.sbcglobal.net
74.166.36.104,adsl-074-166-036-104.sip.asm.bellsouth.net
166.198.164.23,mobile-166-198-164-023.mycingular.net
71.129.12.232,adsl-71-129-12-232.dsl.pltn13.pacbell.net
108.215.67.221,108-215-67-221.lightspeed.dybhfl.sbcglobal.net
108.197.208.106,108-197-208-106.lightspeed.jcvlfl.sbcglobal.net
70.143.108.85,70-143-108-85.lightspeed.hstntx.sbcglobal.net
68.213.160.253,adsl-068-213-160-253.sip.btr.bellsouth.net
108.81.113.142,108-81-113-142.lightspeed.jcsnms.sbcglobal.net
108.65.18.132,108-65-18-132.lightspeed.elpstx.sbcglobal.net
108.250.77.87,108-250-77-87.lightspeed.milwwi.sbcglobal.net
108.242.231.164,108-242-231-164.lightspeed.moblal.sbcglobal.net
108.233.150.224,108-233-150-224.lightspeed.frokca.sbcglobal.net
104.59.106.21,104-59-106-21.lightspeed.hstntx.sbcglobal.net
104.8.189.4,104-8-189-4.lightspeed.nworla.sbcglobal.net
99.91.158.27,99-91-158-27.lightspeed.sndgca.sbcglobal.net
69.213.63.231,adsl-69-213-63-231.dsl.ipltin.ameritech.net
99.114.93.160,99-114-93-160.lightspeed.miamfl.sbcglobal.net
108.246.16.36,108-246-16-36.lightspeed.frokca.sbcglobal.net
166.72.111.94,slip166-72-111-94.oh.us.prserv.net
108.66.214.150,108-66-214-150.lightspeed.rlghnc.sbcglobal.net
63.197.78.186,adsl-63-197-78-186.dsl.snfc21.pacbell.net
76.209.95.205,76-209-95-205.lightspeed.jcvlfl.sbcglobal.net
99.33.212.0,99-33-212-0.lightspeed.wlfrct.sbcglobal.net
76.208.71.19,adsl-76-208-71-19.dsl.mdsnwi.sbcglobal.net
99.124.139.70,99-124-139-70.lightspeed.iplsin.sbcglobal.net
172.7.59.88,172-7-59-88.lightspeed.rcsntx.sbcglobal.net
64.252.207.100,64-252-207-100.lightspeed.jcsnms.sbcglobal.net
67.113.241.212,adsl-67-113-241-212.dsl.snfc21.pacbell.net
69.106.47.146,adsl-69-106-47-146.dsl.pltn13.pacbell.net
69.223.79.18,69-223-79-18.lightspeed.brhmal.sbcglobal.net
99.137.167.77,adsl-99-137-167-77.dsl.chcgil.sbcglobal.net
69.228.94.78,adsl-69-228-94-78.dsl.pltn13.pacbell.net
99.71.113.106,99-71-113-106.lightspeed.rcsntx.sbcglobal.net
70.254.151.53,adsl-70-254-151-53.dsl.wcfltx.swbell.net
75.48.232.76,adsl-75-48-232-76.dsl.klmzmi.sbcglobal.net
76.238.177.232,76-238-177-232.lightspeed.hstntx.sbcglobal.net
12.34.218.73,mail.mashekco.com
75.46.19.191,adsl-75-46-19-191.dsl.sfldmi.sbcglobal.net
45.18.134.248,45-18-134-248.lightspeed.livnmi.sbcglobal.net
129.37.70.126,slip-129-37-70-126.sc.us.prserv.net
99.63.225.31,99-63-225-31.lightspeed.gnvlsc.sbcglobal.net
104.176.164.199,104-176-164-199.lightspeed.tukrga.sbcglobal.net
108.199.24.37,108-199-24-37.lightspeed.irvnca.sbcglobal.net
107.142.188.201,107-142-188-201.lightspeed.miamfl.sbcglobal.net
107.203.103.158,107-203-103-158.lightspeed.sndgca.sbcglobal.net
68.123.103.68,adsl-68-123-103-68.dsl.scrm01.pacbell.net
162.201.232.250,162-201-232-250.lightspeed.wchtks.sbcglobal.net
99.5.203.213,99-5-203-213.lightspeed.jcvlfl.sbcglobal.net
99.46.93.251,99-46-93-251.lightspeed.sndgca.sbcglobal.net
45.21.185.74,45-21-185-74.lightspeed.btrgla.sbcglobal.net
76.234.71.156,76-234-71-156.lightspeed.frokca.sbcglobal.net
76.197.207.175,76-197-207-175.lightspeed.rcsntx.sbcglobal.net
149.51.159.173,undefined.hostname.localhost
108.73.138.220,108-73-138-220.lightspeed.frokca.sbcglobal.net
162.204.153.108,162-204-153-108.lightspeed.sndgca.sbcglobal.net
67.127.12.80,adsl-67-127-12-80.dsl.lsan03.pacbell.net
99.3.60.92,99-3-60-92.lightspeed.ltrkar.sbcglobal.net
99.151.0.114,99-151-0-114.lightspeed.snrmca.sbcglobal.net
209.187.122.200,bos-wan-c-200.bos.dsl.cerfnet.com
107.214.37.252,107-214-37-252.lightspeed.hstntx.sbcglobal.net
172.2.17.44,172-2-17-44.lightspeed.sntcca.sbcglobal.net
108.69.188.55,108-69-188-55.lightspeed.lbcktx.sbcglobal.net
70.234.67.162,70-234-67-162.lightspeed.jcvlfl.sbcglobal.net
71.153.214.95,adsl-71-153-214-95.dsl.rcsntx.sbcglobal.net
107.143.253.51,107-143-253-51.lightspeed.wchtks.sbcglobal.net
65.43.144.91,65-43-144-91.lightspeed.jcvlfl.sbcglobal.net
107.220.143.151,107-220-143-151.lightspeed.austtx.sbcglobal.net
99.67.214.234,adsl-99-67-214-234.dsl.pltn13.sbcglobal.net
99.164.160.213,99-164-160-213.lightspeed.tpkaks.sbcglobal.net
12.66.55.87,slip-12-66-55-87.mis.prserv.net
76.218.28.230,76-218-28-230.lightspeed.bcvloh.sbcglobal.net
12.64.115.255,slip-12-64-115-255.mis.prserv.net
107.214.62.15,107-214-62-15.lightspeed.tukrga.sbcglobal.net
70.154.238.80,adsl-070-154-238-080.sip.dab.bellsouth.net
23.120.44.222,23-120-44-222.lightspeed.irvnca.sbcglobal.net
69.226.245.63,69-226-245-63.lightspeed.rcsntx.sbcglobal.net
104.11.86.93,104-11-86-93.lightspeed.wlfrct.sbcglobal.net
162.194.166.12,162-194-166-12.lightspeed.irvnca.sbcglobal.net
104.182.98.128,104-182-98-128.lightspeed.rcsntx.sbcglobal.net
104.53.131.69,104-53-131-69.lightspeed.knvltn.sbcglobal.net
108.81.22.198,108-81-22-198.lightspeed.irvnca.sbcglobal.net
70.139.226.49,70-139-226-49.lightspeed.hstntx.sbcglobal.net
104.13.108.50,104-13-108-50.lightspeed.cntmoh.sbcglobal.net
107.130.96.139,107-130-96-139.lightspeed.sntcca.sbcglobal.net
71.142.56.234,adsl-71-142-56-234.dsl.scrm01.pacbell.net
66.139.215.25,adsl-66-139-215-25.dsl.rcsntx.swbell.net
108.70.63.193,adsl-108-70-63-193.dsl.sfldmi.sbcglobal.net
45.24.93.151,45-24-93-151.lightspeed.irvnca.sbcglobal.net
99.7.93.131,99-7-93-131.lightspeed.livnmi.sbcglobal.net
76.246.65.3,76-246-65-3.lightspeed.irvnca.sbcglobal.net
68.123.82.254,adsl-68-123-82-254.dsl.lsan03.pacbell.net
172.8.188.3,172-8-188-3.lightspeed.crchtx.sbcglobal.net
76.254.31.126,adsl-76-254-31-126.dsl.pltn13.sbcglobal.net
99.154.190.99,adsl-99-154-190-99.dsl.chcgil.sbcglobal.net
23.112.180.19,23-112-180-19.lightspeed.chrlnc.sbcglobal.net
67.36.218.66,adsl-67-36-218-66.dsl.chcgil.ameritech.net
104.53.32.78,104-53-32-78.lightspeed.bcvloh.sbcglobal.net
99.13.67.213,99-13-67-213.lightspeed.cicril.sbcglobal.net
99.100.79.240,99-100-79-240.lightspeed.rcsntx.sbcglobal.net
71.138.247.110,adsl-71-138-247-110.dsl.sndg02.pacbell.net
108.252.182.39,108-252-182-39.lightspeed.cicril.sbcglobal.net
45.29.143.109,45-29-143-109.lightspeed.sntcca.sbcglobal.net
107.194.3.54,107-194-3-54.lightspeed.jcvlfl.sbcglobal.net
108.217.232.235,108-217-232-235.lightspeed.wlfrct.sbcglobal.net
75.24.1.209,adsl-75-24-1-209.dsl.yntwoh.sbcglobal.net
69.222.194.195,69-222-194-195.lightspeed.ltrkar.sbcglobal.net
64.171.249.197,adsl-64-171-249-197.dsl.snfc21.pacbell.net
108.86.217.134,108-86-217-134.lightspeed.cicril.sbcglobal.net
63.207.58.103,adsl-63-207-58-103.dsl.lsan03.pacbell.net
70.139.192.246,70-139-192-246.lightspeed.hstntx.sbcglobal.net
75.13.28.211,adsl-75-13-28-211.dsl.irvnca.sbcglobal.net
76.202.62.133,adsl-76-202-62-133.dsl.pltn13.sbcglobal.net
64.168.82.198,adsl-64-168-82-198.dsl.snfc21.pacbell.net
107.137.154.147,107-137-154-147.lightspeed.chrlnc.sbcglobal.net
67.33.147.185,adsl-67-33-147-185.asm.bellsouth.net
108.87.55.134,108-87-55-134.lightspeed.wepbfl.sbcglobal.net
149.51.23.17,undefined.hostname.localhost
99.122.90.191,99-122-90-191.lightspeed.mmphtn.sbcglobal.net
12.135.78.20,20.suh78.dlls.dlstx31ur.dsl.att.net
99.157.225.181,99-157-225-181.lightspeed.bcvloh.sbcglobal.net
68.75.21.194,adsl-68-75-21-194.dsl.wotnoh.ameritech.net
99.44.244.18,99-44-244-18.lightspeed.jcvlfl.sbcglobal.net
74.181.150.182,adsl-074-181-150-182.sip.sdf.bellsouth.net
69.225.138.111,adsl-69-225-138-111.dsl.irvnca.pacbell.net
108.218.221.85,108-218-221-85.lightspeed.spfdmo.sbcglobal.net
99.163.28.80,99-163-28-80.lightspeed.kscyks.sbcglobal.net
108.215.92.123,108-215-92-123.uvs.jcsnms.sbcglobal.net
99.103.197.166,99-103-197-166.lightspeed.sntcca.sbcglobal.net
129.37.212.40,slip129-37-212-40.ny.us.prserv.net
104.184.86.54,104-184-86-54.lightspeed.cicril.sbcglobal.net
67.114.6.4,adsl-67-114-6-4.dsl.frs2ca.pacbell.net
74.232.189.101,adsl-074-232-189-101.sip.asm.bellsouth.net
67.37.109.75,67-37-109-75.ded.ameritech.net
74.237.41.80,adsl-074-237-041-080.sip.asm.bellsouth.net
166.199.79.114,mobile-166-199-079-114.mycingular.net
104.190.96.108,104-190-96-108.lightspeed.sgnwmi.sbcglobal.net
71.146.164.88,71-146-164-88.lightspeed.moblal.sbcglobal.net
75.46.153.91,75-46-153-91.lightspeed.tukrga.sbcglobal.net
172.5.10.209,172-5-10-209.lightspeed.hstntx.sbcglobal.net
107.95.19.13,mobile-107-95-19-13.mycingular.net
45.29.137.200,45-29-137-200.lightspeed.sntcca.sbcglobal.net
108.210.238.214,108-210-238-214.uvs.iplsin.sbcglobal.net
75.52.103.208,75-52-103-208.lightspeed.chrlnc.sbcglobal.net
98.73.252.204,adsl-098-073-252-204.sip.asm.bellsouth.net
63.195.122.144,adsl-63-195-122-144.dsl.snfc21.pacbell.net
69.152.203.110,adsl-69-152-203-110.dsl.fyvlar.swbell.net
67.32.163.114,adsl-067-032-163-114.sip.sdf.bellsouth.net
69.151.201.17,69-151-201-17.lightspeed.gnbonc.sbcglobal.net
99.124.152.56,99-124-152-56.lightspeed.sntcca.sbcglobal.net
99.101.46.187,99-101-46-187.lightspeed.rcsntx.sbcglobal.net
72.149.181.49,adsl-072-149-181-049.sip.int.bellsouth.net
67.37.83.95,ppp-67-37-83-95.dialup.sfldmi.ameritech.net
107.214.108.48,107-214-108-48.lightspeed.irvnca.sbcglobal.net
75.18.172.73,adsl-75-18-172-73.dsl.pltn13.sbcglobal.net
68.92.94.216,adsl-68-92-94-216.dsl.stlsmo.swbell.net
64.123.213.205,adsl-64-123-213-205.dsl.hstntx.swbell.net
68.79.208.86,adsl-68-79-208-86.dsl.chcgil.ameritech.net
107.129.97.75,107-129-97-75.lightspeed.bcvloh.sbcglobal.net
99.48.163.179,99-48-163-179.lightspeed.brfrct.sbcglobal.net
108.237.96.197,108-237-96-197.lightspeed.frsnca.sbcglobal.net
74.182.190.10,adsl-074-182-190-010.sip.btr.bellsouth.net
99.109.239.123,99-109-239-123.lightspeed.mssnks.sbcglobal.net
99.37.190.29,99-37-190-29.lightspeed.irvnca.sbcglobal.net
99.146.28.83,adsl-99-146-28-83.dsl.lsan03.sbcglobal.net
107.223.211.87,107-223-211-87.lightspeed.sntcca.sbcglobal.net
104.51.0.216,104-51-0-216.lightspeed.miamfl.sbcglobal.net
75.31.73.211,75-31-73-211.lightspeed.sntcca.sbcglobal.net
107.207.25.164,107-207-25-164.lightspeed.irvnca.sbcglobal.net
172.2.248.212,172-2-248-212.lightspeed.milwwi.sbcglobal.net
99.35.179.3,99-35-179-3.lightspeed.hstntx.sbcglobal.net
75.11.77.153,adsl-75-11-77-153.dsl.chcgil.sbcglobal.net
76.205.4.87,76-205-4-87.lightspeed.stlsmo.sbcglobal.net
75.43.93.203,adsl-75-43-93-203.dsl.hrlntx.sbcglobal.net
75.0.231.39,adsl-75-0-231-39.dsl.crchtx.sbcglobal.net
23.113.128.141,23-113-128-141.lightspeed.austtx.sbcglobal.net
69.237.169.15,adsl-69-237-169-15.dsl.pltn13.pacbell.net
172.10.227.73,172-10-227-73.lightspeed.livnmi.sbcglobal.net
99.39.157.2,99-39-157-2.lightspeed.ltrkar.sbcglobal.net
12.66.197.26,slip-12-66-197-26.mis.prserv.net
104.186.18.147,104-186-18-147.lightspeed.chrlnc.sbcglobal.net
99.123.62.16,99-123-62-16.lightspeed.brhmal.sbcglobal.net
104.50.125.235,104-50-125-235.lightspeed.irvnca.sbcglobal.net
208.190.113.173,208-190-113-173.ded.swbell.net
69.220.187.230,adsl-69-220-187-230.dsl.chcgil.ameritech.net
74.236.201.144,adsl-074-236-201-144.sip.clt.bellsouth.net
23.120.56.246,23-120-56-246.lightspeed.bcvloh.sbcglobal.net
104.182.66.192,104-182-66-192.lightspeed.sndgca.sbcglobal.net
104.187.209.10,104-187-209-10.lightspeed.gnbonc.sbcglobal.net
70.228.33.172,adsl-70-228-33-172.dsl.chcgil.ameritech.net
108.67.48.148,108-67-48-148.lightspeed.irvnca.sbcglobal.net
99.56.101.114,99-56-101-114.lightspeed.miamfl.sbcglobal.net
107.198.109.115,107-198-109-115.lightspeed.lsvlky.sbcglobal.net
12.147.30.152,fullsailalum.com
108.92.81.78,108-92-81-78.lightspeed.iplsin.sbcglobal.net
66.120.113.134,adsl-66-120-113-134.dsl.sndg02.pacbell.net
45.18.133.130,45-18-133-130.lightspeed.livnmi.sbcglobal.net
76.228.196.54,76-228-196-54.lightspeed.brhmal.sbcglobal.net
99.12.223.243,99-12-223-243.lightspeed.hstntx.sbcglobal.net
68.248.114.224,adsl-68-248-114-224.dsl.ipltin.ameritech.net
104.8.119.145,104-8-119-145.lightspeed.rcsntx.sbcglobal.net
70.254.147.116,adsl-70-254-147-116.dsl.wcfltx.swbell.net
65.67.254.113,adsl-65-67-254-113.dsl.okcyok.swbell.net
99.31.252.25,adsl-99-31-252-25.dsl.skt2ca.sbcglobal.net
99.155.159.63,99-155-159-63.lightspeed.chrlnc.sbcglobal.net
107.94.173.172,mobile-107-94-173-172.mycingular.net
75.54.119.237,75-54-119-237.lightspeed.irvnca.sbcglobal.net
99.187.204.114,adsl-99-187-204-114.dsl.irvnca.sbcglobal.net
108.196.9.108,108-196-9-108.lightspeed.tukrga.sbcglobal.net
67.114.132.99,adsl-67-114-132-99.dsl.sntc01.pacbell.net
70.134.209.228,70-134-209-228.lightspeed.sndgca.sbcglobal.net
69.182.48.175,69-182-48-175.windsonginc.com
99.66.231.120,99-66-231-120.lightspeed.irvnca.sbcglobal.net
162.198.67.143,162-198-67-143.lightspeed.sndgca.sbcglobal.net
64.165.232.68,adsl-64-165-232-68.dsl.lsan03.pacbell.net
74.239.29.92,adsl-074-239-029-092.sip.tys.bellsouth.net
23.121.241.101,23-121-241-101.lightspeed.sntcca.sbcglobal.net
166.205.194.3,mobile-166-205-194-003.mycingular.net
23.118.108.46,23-118-108-46.lightspeed.sntcca.sbcglobal.net
108.197.32.182,108-197-32-182.lightspeed.sndgca.sbcglobal.net
75.63.134.178,adsl-75-63-134-178.dsl.mtry01.sbcglobal.net
162.194.232.80,162-194-232-80.lightspeed.cicril.sbcglobal.net
108.194.111.232,adsl-108-194-111-232.dsl.rcsntx.sbcglobal.net
74.188.121.118,adsl-074-188-121-118.sip.asm.bellsouth.net
70.135.147.96,70-135-147-96.lightspeed.stlsmo.sbcglobal.net
23.115.184.162,23-115-184-162.lightspeed.moblal.sbcglobal.net
108.252.254.3,108-252-254-3.lightspeed.sntcca.sbcglobal.net
99.167.93.108,99-167-93-108.lightspeed.irvnca.sbcglobal.net
104.50.132.63,104-50-132-63.lightspeed.renonv.sbcglobal.net
216.103.83.198,adsl-216-103-83-198.dsl.snfc21.pacbell.net
99.123.248.106,99-123-248-106.lightspeed.chrlnc.sbcglobal.net
99.125.177.211,99-125-177-211.lightspeed.miamfl.sbcglobal.net
70.244.180.84,ppp-70-244-180-84.dsl.rcsntx.swbell.net
162.239.131.130,162-239-131-130.lightspeed.cicril.sbcglobal.net
12.105.236.80,1009host80.starwoodbroadband.com
63.205.15.193,adsl-63-205-15-193.dsl.scrm01.pacbell.net
99.14.234.78,adsl-99-14-234-78.dsl.chcgil.sbcglobal.net
104.52.171.47,104-52-171-47.lightspeed.jcsnms.sbcglobal.net
107.139.160.136,107-139-160-136.lightspeed.iplsin.sbcglobal.net
108.197.105.139,108-197-105-139.lightspeed.sntcca.sbcglobal.net
108.208.41.11,108-208-41-11.lightspeed.rcsntx.sbcglobal.net
162.195.210.232,162-195-210-232.lightspeed.irvnca.sbcglobal.net
108.85.218.93,108-85-218-93.lightspeed.livnmi.sbcglobal.net
107.192.66.245,107-192-66-245.lightspeed.wepbfl.sbcglobal.net
75.48.185.31,adsl-75-48-185-31.dsl.wchtks.sbcglobal.net
108.235.41.10,108-235-41-10.lightspeed.stlsmo.sbcglobal.net
12.24.246.220,nat220.rainier.com
70.237.54.58,adsl-70-237-54-58.dsl.pltn13.sbcglobal.net
76.200.198.177,76-200-198-177.lightspeed.clmboh.sbcglobal.net
70.140.43.16,70-140-43-16.lightspeed.hstntx.sbcglobal.net
104.59.234.179,104-59-234-179.lightspeed.rlghnc.sbcglobal.net
108.66.128.0,108-66-128-0.lightspeed.sntcca.sbcglobal.net
108.231.21.161,108-231-21-161.lightspeed.irvnca.sbcglobal.net
108.237.221.199,108-237-221-199.lightspeed.cicril.sbcglobal.net
23.112.101.101,23-112-101-101.lightspeed.miamfl.sbcglobal.net
65.64.242.182,adsl-65-64-242-182.dsl.hstntx.swbell.net
67.65.15.157,adsl-67-65-15-157.dsl.austtx.swbell.net
99.185.12.210,adsl-99-185-12-210.dsl.hstntx.sbcglobal.net
76.228.36.50,adsl-76-228-36-50.dsl.hrlntx.sbcglobal.net
104.179.166.64,104-179-166-64.lightspeed.chrlnc.sbcglobal.net
99.137.228.62,99-137-228-62.lightspeed.frokca.sbcglobal.net
66.141.12.94,adsl-66-141-12-94.dsl.hstntx.swbell.net
76.193.181.118,adsl-76-193-181-118.dsl.chcgil.sbcglobal.net
108.201.110.215,108-201-110-215.lightspeed.cicril.sbcglobal.net
69.216.41.66,adsl-69-216-41-66.dsl.applwi.ameritech.net
104.55.13.168,104-55-13-168.lightspeed.knvltn.sbcglobal.net
75.53.173.72,75-53-173-72.lightspeed.sktnca.sbcglobal.net
99.6.227.54,99-6-227-54.lightspeed.rcsntx.sbcglobal.net
108.95.16.18,108-95-16-18.lightspeed.spfdmo.sbcglobal.net
75.63.199.182,adsl-75-63-199-182.dsl.snantx.sbcglobal.net
99.119.198.12,99-119-198-12.lightspeed.sntcca.sbcglobal.net
108.84.204.111,adsl-108-84-204-111.dsl.sfldmi.sbcglobal.net
108.208.52.123,108-208-52-123.lightspeed.sntcca.sbcglobal.net
74.237.38.206,adsl-074-237-038-206.sip.asm.bellsouth.net
104.58.127.87,104-58-127-87.lightspeed.milwwi.sbcglobal.net
75.37.146.153,adsl-75-37-146-153.dsl.irvnca.sbcglobal.net
104.7.33.54,104-7-33-54.lightspeed.knvltn.sbcglobal.net
76.216.216.223,76-216-216-223.lightspeed.irvnca.sbcglobal.net
45.29.93.194,45-29-93-194.lightspeed.iplsin.sbcglobal.net
216.101.169.232,adsl-216-101-169-232.dsl.snfc21.pacbell.net
64.108.29.203,adsl-64-108-29-203.dsl.chcgil.ameritech.net
107.214.60.145,107-214-60-145.lightspeed.tukrga.sbcglobal.net
108.64.5.44,108-64-5-44.lightspeed.miamfl.sbcglobal.net
23.125.10.54,23-125-10-54.lightspeed.gdrpmi.sbcglobal.net
108.254.118.10,108-254-118-10.lightspeed.bcvloh.sbcglobal.net
67.120.170.75,adsl-67-120-170-75.dsl.lsan03.pacbell.net
99.7.95.232,99-7-95-232.lightspeed.livnmi.sbcglobal.net
99.6.248.209,99-6-248-209.lightspeed.artnca.sbcglobal.net
71.150.205.92,71-150-205-92.lightspeed.clmboh.sbcglobal.net
76.247.232.50,adsl-76-247-232-50.dsl.dytnoh.sbcglobal.net
75.40.100.202,adsl-75-40-100-202.dsl.chcgil.sbcglobal.net
64.163.142.202,adsl-64-163-142-202.dsl.snfc21.pacbell.net
108.213.207.61,108-213-207-61.lightspeed.wlfrct.sbcglobal.net
216.101.160.113,adsl-216-101-160-113.dsl.snfc21.pacbell.net
104.11.89.68,104-11-89-68.lightspeed.miamfl.sbcglobal.net
74.245.75.26,adsl-074-245-075-026.sip.msy.bellsouth.net
74.238.4.165,adsl-074-238-004-165.sip.mia.bellsouth.net
68.213.230.129,adsl-068-213-230-129.sip.mia.bellsouth.net
107.210.129.55,107-210-129-55.lightspeed.rcsntx.sbcglobal.net
69.232.102.149,69-232-102-149.lightspeed.snantx.sbcglobal.net
65.6.0.21,adsl-65-6-0-21.mia.bellsouth.net
45.26.60.15,45-26-60-15.lightspeed.cicril.sbcglobal.net
66.140.153.236,adsl-66-140-153-236.dsl.hstntx.swbell.net
12.101.240.237,237.mufda.lsan.la6ca01r1.dsl.att.net
108.240.254.53,108-240-254-53.lightspeed.cicril.sbcglobal.net
166.205.21.79,mobile-166-205-021-079.mycingular.net
166.192.170.201,mobile-166-192-170-201.mycingular.net
99.188.249.175,99-188-249-175.lightspeed.cicril.sbcglobal.net
99.24.185.77,adsl-99-24-185-77.dsl.milwwi.sbcglobal.net
99.35.229.243,99-35-229-243.lightspeed.brhmal.sbcglobal.net
108.207.102.16,108-207-102-16.lightspeed.cicril.sbcglobal.net
69.106.179.149,adsl-69-106-179-149.dsl.frs2ca.pacbell.net
209.30.240.241,209-30-240-241.lightspeed.frsnca.sbcglobal.net
107.196.186.86,107-196-186-86.lightspeed.cicril.sbcglobal.net
108.220.95.244,108-220-95-244.lightspeed.miamfl.sbcglobal.net
64.165.66.4,adsl-64-165-66-4.dsl.scrm01.pacbell.net
12.75.156.111,111.new-york-20rh15-16rt.ny.dial-access.att.net
107.197.59.167,107-197-59-167.lightspeed.hstntx.sbcglobal.net
162.192.210.75,162-192-210-75.lightspeed.spfdmo.sbcglobal.net
99.12.197.230,99-12-197-230.lightspeed.austtx.sbcglobal.net
104.51.215.192,104-51-215-192.lightspeed.snantx.sbcglobal.net
12.101.162.171,171.muca.grbr.washdctt.dsl.att.net
162.236.88.56,162-236-88-56.lightspeed.stlsmo.sbcglobal.net
63.197.132.226,adsl-63-197-132-226.dsl.scrm01.pacbell.net
63.242.249.56,56.mulf.nyrk.nycenycp.dsl.att.net
76.217.244.93,76-217-244-93.lightspeed.cntmoh.sbcglobal.net
162.203.107.237,162-203-107-237.lightspeed.irvnca.sbcglobal.net
107.208.207.170,107-208-207-170.lightspeed.rcsntx.sbcglobal.net
75.53.237.77,adsl-75-53-237-77.dsl.irvnca.sbcglobal.net
70.233.128.11,adsl-70-233-128-11.dsl.okcyok.sbcglobal.net
108.211.226.3,108-211-226-3.lightspeed.chrlnc.sbcglobal.net
99.119.137.122,adsl-99-119-137-122.dsl.spf2mo.sbcglobal.net
104.2.213.155,104-2-213-155.lightspeed.bcvloh.sbcglobal.net
99.72.198.9,99-72-198-9.lightspeed.mssnks.sbcglobal.net
108.72.242.241,108-72-242-241.lightspeed.tukrga.sbcglobal.net
76.235.155.7,adsl-76-235-155-7.dsl.pltn13.sbcglobal.net
162.227.160.89,162-227-160-89.lightspeed.sntcca.sbcglobal.net
70.141.29.229,adsl-70-141-29-229.dsl.sgnwmi.sbcglobal.net
99.1.162.73,adsl-99-1-162-73.dsl.sfldmi.sbcglobal.net
107.195.190.183,107-195-190-183.lightspeed.frokca.sbcglobal.net
162.227.162.78,162-227-162-78.lightspeed.sntcca.sbcglobal.net
69.223.223.151,69-223-223-151.lightspeed.moblal.sbcglobal.net
108.225.27.250,108-225-27-250.lightspeed.irvnca.sbcglobal.net
65.71.52.129,65-71-52-129.twsco.com
63.206.225.91,adsl-63-206-225-91.dsl.lsan03.pacbell.net
67.34.114.47,adsl-067-034-114-047.sip.mia.bellsouth.net
99.140.10.118,adsl-99-140-10-118.dsl.lsan03.sbcglobal.net
99.130.35.113,99-130-35-113.lightspeed.frokca.sbcglobal.net
99.5.212.47,99-5-212-47.lightspeed.snantx.sbcglobal.net
99.159.74.24,99-159-74-24.lightspeed.tukrga.sbcglobal.net
23.124.192.146,23-124-192-146.lightspeed.irvnca.sbcglobal.net
108.195.201.202,adsl-108-195-201-202.uvs.frokca.sbcglobal.net
99.111.150.183,99-111-150-183.lightspeed.livnmi.sbcglobal.net
99.152.197.73,adsl-99-152-197-73.dsl.rcsntx.sbcglobal.net
71.140.184.19,adsl-71-140-184-19.dsl.pltn13.pacbell.net
66.126.249.64,adsl-66-126-249-64.dsl.sntc01.pacbell.net
99.47.247.208,99-47-247-208.lightspeed.irvnca.sbcglobal.net
99.71.140.161,99-71-140-161.lightspeed.sndgca.sbcglobal.net
99.34.48.116,adsl-99-34-48-116.dsl.hstntx.sbcglobal.net
70.250.18.52,adsl-70-250-18-52.dsl.rcsntx.swbell.net
76.208.173.161,adsl-76-208-173-161.dsl.lsan03.sbcglobal.net
99.147.119.49,99-147-119-49.lightspeed.jcsnms.sbcglobal.net
104.181.109.13,104-181-109-13.lightspeed.mssnks.sbcglobal.net
23.119.122.173,23-119-122-173.lightspeed.sntcca.sbcglobal.net
166.205.59.177,mobile-166-205-059-177.mycingular.net
`
        },
        {
            title: `Example FSL Prompt For Regex Generation`,
            content : `Here is a class of records:
            Class Name: SBCGlobal_Lightspeed_GeographicEncoding
            Examples: 99-170-164-205.lightspeed.tukrga.sbcglobal.net, 107-194-77-243.lightspeed.austtx.sbcglobal.net, 162-193-172-90.lightspeed.miamfl.sbcglobal.net, 104-183-175-2.lightspeed.okcbok.sbcglobal.net, 104-187-221-175.lightspeed.toldoh.sbcglobal.net`
        },
        {
            title : `Records Obtained By Filtering For Possible Hints`,
            content: `adsl-66-125-195-112.dsl.lsan03.pacbell.net
104-61-169-217.lightspeed.jcsnms.sbcglobal.net
69-149-114-14.lightspeed.frokca.sbcglobal.net
adsl-108-86-16-125.dsl.emhril.sbcglobal.net
162-231-40-97.lightspeed.mmphtn.sbcglobal.net
adsl-108-226-187-144.dsl.pltn13.sbcglobal.net
99-37-165-59.lightspeed.hstntx.sbcglobal.net
adsl-71-145-217-175.dsl.austtx.sbcglobal.net
adsl-63-200-242-233.dsl.lsan03.pacbell.net
67-114-147-174.westecplastics.com
adsl-068-157-175-251.sip.asm.bellsouth.net
mobile-107-95-209-180.mycingular.net
104-50-238-254.lightspeed.chtnsc.sbcglobal.net
adsl-67-117-69-154.dsl.sntc01.pacbell.net
adsl-66-136-139-76.dsl.kscymo.swbell.net
adsl-99-49-73-145.dsl.frs2ca.sbcglobal.net
adsl-65-6-100-140.msy.bellsouth.net
107-197-46-143.lightspeed.cicril.sbcglobal.net
104-179-225-182.lightspeed.cicril.sbcglobal.net
104-57-14-144.lightspeed.clmboh.sbcglobal.net
adsl-99-149-163-47.dsl.sfldmi.sbcglobal.net
108-236-53-63.lightspeed.mmphtn.sbcglobal.net
99-155-105-26.lightspeed.btrgla.sbcglobal.net
adsl-208-190-157-229.dialup.austtx.swbell.net
162-192-226-108.lightspeed.rcsntx.sbcglobal.net
99-110-35-176.lightspeed.cicril.sbcglobal.net
adsl-072-151-031-141.sip.gsp.bellsouth.net
23-115-231-244.lightspeed.milwwi.sbcglobal.net
adsl-71-143-110-14.dsl.pltn13.pacbell.net
adsl-76-221-203-2.dsl.pltn13.sbcglobal.net
23-121-152-180.lightspeed.sntcca.sbcglobal.net
adsl-68-79-113-244.dsl.sfldmi.ameritech.net
108-95-0-36.lightspeed.sndgca.sbcglobal.net
adsl-108-74-238-179.dsl.sfldmi.sbcglobal.net
162-206-142-42.lightspeed.cicril.sbcglobal.net
108-79-43-152.lightspeed.livnmi.sbcglobal.net
adsl-64-162-90-64.dsl.snfc21.pacbell.net
adsl-76-194-34-103.dsl.hstntx.sbcglobal.net
45-30-70-149.lightspeed.hstntx.sbcglobal.net
172-10-230-189.lightspeed.livnmi.sbcglobal.net
76-250-45-110.lightspeed.mdsnwi.sbcglobal.net
108-206-76-239.lightspeed.bcvloh.sbcglobal.net
adsl-75-53-4-8.dsl.sfldmi.sbcglobal.net
70-130-126-27.lightspeed.snantx.sbcglobal.net
23-127-217-88.lightspeed.rcsntx.sbcglobal.net
99-117-205-10.lightspeed.frokca.sbcglobal.net
104-5-206-184.lightspeed.tukrga.sbcglobal.net
99-115-175-68.lightspeed.brhmal.sbcglobal.net
adsl-76-238-146-189.dsl.irvnca.sbcglobal.net
adsl-63-194-104-174.dsl.snlo01.pacbell.net
99-54-188-179.lightspeed.rsllil.sbcglobal.net
adsl-74-232-123-57.asm.bellsouth.net
adsl-074-169-075-061.sip.bct.bellsouth.net
adsl-065-082-232-046.sip.btr.bellsouth.net
104-187-22-48.lightspeed.btrgla.sbcglobal.net
99-38-117-215.lightspeed.livnmi.sbcglobal.net
107-220-18-169.lightspeed.iplsin.sbcglobal.net
104-52-59-135.lightspeed.austtx.sbcglobal.net
ppp-206-170-1-62.dialup.snfc21.pacbell.net
adsl-99-39-145-118.dsl.pltn13.sbcglobal.net
99-16-82-133.lightspeed.wyndmi.sbcglobal.net
adsl-75-45-13-233.dsl.scrm01.sbcglobal.net
76-232-47-228.lightspeed.clmboh.sbcglobal.net
45-25-156-251.lightspeed.wepbfl.sbcglobal.net
adsl-63-205-113-227.dsl.lsan03.pacbell.net
99-182-219-237.lightspeed.hstntx.sbcglobal.net
adsl-65-66-162-39.dsl.snantx.swbell.net
adsl-76-231-32-168.dsl.irvnca.sbcglobal.net
adsl-76-226-122-43.dsl.sfldmi.sbcglobal.net
71-146-114-50.lightspeed.rcsntx.sbcglobal.net
162-196-184-82.lightspeed.milwwi.sbcglobal.net
172-11-220-16.lightspeed.cntmoh.sbcglobal.net
76-222-127-118.lightspeed.cntmoh.sbcglobal.net
71-158-95-77.lightspeed.rlghnc.sbcglobal.net
adsl-75-36-24-73.dsl.wotnoh.sbcglobal.net
45-17-55-158.lightspeed.brhmal.sbcglobal.net
adsl-99-170-251-212.dsl.ipltin.sbcglobal.net
75-36-110-206.lightspeed.mmphtn.sbcglobal.net
104-5-64-14.lightspeed.rcsntx.sbcglobal.net
adsl-107-219-161-219.lightspeed.milwwi.sbcglobal.net
162-194-79-229.lightspeed.livnmi.sbcglobal.net
adsl-074-188-227-005.sip.asm.bellsouth.net
108-89-211-246.lightspeed.miamfl.sbcglobal.net
adsl-99-2-28-224.dsl.snfc21.sbcglobal.net
ppp-69-217-31-167.dsl.applwi.ameritech.net
adsl-99-140-149-71.dsl.pltn13.sbcglobal.net
76-212-121-88.lightspeed.dllstx.sbcglobal.net
107-138-36-53.lightspeed.mmphtn.sbcglobal.net
adsl-64-166-114-227.dsl.lsan03.pacbell.net
104-52-83-192.lightspeed.tukrga.sbcglobal.net
107-195-138-58.lightspeed.nworla.sbcglobal.net
108-233-221-78.lightspeed.dctril.sbcglobal.net
adsl-69-226-26-69.dsl.sndg02.pacbell.net
adsl-209-233-186-213.dsl.snfc21.pacbell.net
104-12-64-131.lightspeed.miamfl.sbcglobal.net
adsl-68-255-136-5.dsl.chcgil.ameritech.net
adsl-63-201-1-165.dsl.snlo01.pacbell.net
adsl-074-238-107-212.sip.bct.bellsouth.net
75-39-98-208.lightspeed.bcwdoh.sbcglobal.net
45-21-239-249.lightspeed.wchtks.sbcglobal.net
adsl-75-5-162-240.dsl.irvnca.sbcglobal.net
70-140-153-195.lightspeed.hstntx.sbcglobal.net
99-164-13-235.lightspeed.cicril.sbcglobal.net
99-190-66-151.lightspeed.cicril.sbcglobal.net
adsl-68-127-57-6.dsl.sktn01.pacbell.net
108-194-231-119.lightspeed.frokca.sbcglobal.net
adsl-074-229-030-244.sip.mia.bellsouth.net
host-68-212-147-130.mia.bellsouth.net
162-234-193-147.lightspeed.lsvlky.sbcglobal.net
adsl-64-217-193-32.prime-art.com
108-68-131-219.lightspeed.stlsmo.sbcglobal.net
108-193-138-130.lightspeed.miamfl.sbcglobal.net
108-203-2-111.lightspeed.rlghnc.sbcglobal.net
108-224-26-79.lightspeed.hstntx.sbcglobal.net
99-180-35-169.lightspeed.rcsntx.sbcglobal.net
108-205-130-8.lightspeed.mtclmi.sbcglobal.net
108-239-116-12.lightspeed.rcsntx.sbcglobal.net
108-68-68-132.lightspeed.tukrga.sbcglobal.net
adsl-99-63-90-19.dsl.chcgil.sbcglobal.net
adsl-99-153-83-202.dsl.chi2ca.sbcglobal.net
104-13-54-131.lightspeed.gnbonc.sbcglobal.net
75-7-138-28.lightspeed.rlghnc.sbcglobal.net
107-209-96-255.lightspeed.stlsmo.sbcglobal.net
45-16-210-29.lightspeed.elpstx.sbcglobal.net
103.chicago-11rh16rt.il.dial-access.att.net
76-250-233-93.lightspeed.wlfrct.sbcglobal.net
162-231-252-108.lightspeed.mssnks.sbcglobal.net
172-11-26-254.lightspeed.snantx.sbcglobal.net
adsl-99-69-48-192.dsl.wlfrct.sbcglobal.net
108-216-128-81.lightspeed.iplsin.sbcglobal.net
108-239-87-127.lightspeed.miamfl.sbcglobal.net
adsl-64-168-167-98.dsl.lsan03.pacbell.net
107-208-4-230.lightspeed.irvnca.sbcglobal.net
23-127-67-188.lightspeed.mssnks.sbcglobal.net
adsl-074-170-132-115.sip.msy.bellsouth.net
99-183-233-154.lightspeed.cicril.sbcglobal.net
162-236-122-157.lightspeed.irvnca.sbcglobal.net
99-27-162-93.lightspeed.wlfrct.sbcglobal.net
108-255-49-44.lightspeed.wepbfl.sbcglobal.net
108-70-232-36.lightspeed.miamfl.sbcglobal.net
45-27-81-28.lightspeed.jcsnms.sbcglobal.net
162-195-65-49.lightspeed.renonv.sbcglobal.net
adsl-71-134-137-62.dsl.pltn13.pacbell.net
adsl-074-173-128-093.sip.mia.bellsouth.net
104-4-230-192.lightspeed.rcsntx.sbcglobal.net
108-75-149-164.lightspeed.tukrga.sbcglobal.net
adsl-99-152-227-198.dsl.irvnca.sbcglobal.net
107-201-117-111.lightspeed.tukrga.sbcglobal.net
adsl-172-0-214-242.dsl.okcyok.sbcglobal.net
172-11-167-227.lightspeed.milwwi.sbcglobal.net
adsl-67-36-227-147.dsl.sfldmi.ameritech.net
104-181-65-0.lightspeed.clmboh.sbcglobal.net
slip-12-64-85-112.mis.prserv.net
adsl-68-255-133-121.dsl.chcgil.ameritech.net
108-216-91-26.lightspeed.bcvloh.sbcglobal.net
adsl-67-112-162-161.dsl.lsan03.pacbell.net
dlvtech.com
adsl-074-237-138-082.sip.gsp.bellsouth.net
69-221-233-252.lightspeed.knvltn.sbcglobal.net
45-31-236-177.lightspeed.brhmal.sbcglobal.net
adsl-75-30-206-237.dsl.sndg02.sbcglobal.net
23-120-33-106.lightspeed.sntcca.sbcglobal.net
adsl-99-38-78-199.dsl.spfdmo.sbcglobal.net
75-28-176-54.lightspeed.evtnil.sbcglobal.net
99-76-255-232.lightspeed.jcsnms.sbcglobal.net
162-239-14-97.lightspeed.stlsmo.sbcglobal.net
adsl-99-31-28-36.dsl.okcyok.sbcglobal.net
104-7-221-160.lightspeed.austtx.sbcglobal.net
adsl-63-199-0-202.dsl.snfc21.pacbell.net
99-152-148-99.lightspeed.ftwotx.sbcglobal.net
adsl-75-52-180-57.dsl.ksc2mo.sbcglobal.net
162-196-204-173.lightspeed.rcsntx.sbcglobal.net
adsl-66-138-151-46.dsl.stlsmo.swbell.net
99-90-230-150.lightspeed.frokca.sbcglobal.net
adsl-098-072-203-168.sip.bct.bellsouth.net
adsl-068-157-082-087.sip.bct.bellsouth.net
104-9-62-241.lightspeed.wepbfl.sbcglobal.net
adsl-68-21-157-30.dsl.milwwi.ameritech.net
99-58-173-50.lightspeed.frokca.sbcglobal.net
107-132-68-74.lightspeed.cicril.sbcglobal.net
76-238-233-161.lightspeed.sndgca.sbcglobal.net
adsl-76-193-191-38.dsl.chcgil.sbcglobal.net
99-110-53-86.lightspeed.sntcca.sbcglobal.net
adsl-76-247-149-30.dsl.lgtpmi.sbcglobal.net
108-239-15-10.lightspeed.sndgca.sbcglobal.net
99-186-103-243.lightspeed.lsvlky.sbcglobal.net
adsl-99-36-171-213.dsl.crchtx.sbcglobal.net
208-191-1-209.customerlinx.com
71-140-203-106.lightspeed.brhmal.sbcglobal.net
adsl-63-204-189-196.dsl.lsan03.pacbell.net
adsl-68-125-95-32.dsl.irvnca.pacbell.net
adsl-107-199-128-132.lightspeed.irvnca.sbcglobal.net
adsl-65-6-56-247.mia.bellsouth.net
adsl-75-48-107-134.dsl.skt2ca.sbcglobal.net
99-127-237-118.lightspeed.mssnks.sbcglobal.net
adsl-99-11-97-98.dsl.sndg02.sbcglobal.net
70-139-175-161.lightspeed.hstntx.sbcglobal.net
auk63r25c010-mgmt.auk63f.cci.att.com
adsl-75-19-71-69.dsl.elpstx.sbcglobal.net
adsl-76-211-71-37.dsl.tul2ok.sbcglobal.net
adsl-66-123-163-166.dsl.sntc01.pacbell.net
adsl-216-102-165-36.dsl.lsan03.pacbell.net
adsl-68-249-127-182.dsl.ipltin.ameritech.net
104-10-60-222.lightspeed.sntcca.sbcglobal.net
adsl-75-5-187-109.dsl.irvnca.sbcglobal.net
108-64-1-58.lightspeed.miamfl.sbcglobal.net
adsl-99-14-119-254.dsl.milwwi.sbcglobal.net
adsl-75-21-160-197.dsl.sgnwmi.sbcglobal.net
adsl-074-247-111-216.sip.mob.bellsouth.net
adsl-65-70-1-41.dsl.ltrkar.swbell.net
99-184-192-93.lightspeed.wepbfl.sbcglobal.net
70-225-236-187.dlsemc.com
104-7-210-252.lightspeed.dybhfl.sbcglobal.net
jabba.loflink.com
99-191-204-93.lightspeed.hlrdoh.sbcglobal.net
172-11-210-144.lightspeed.stlsmo.sbcglobal.net
99-146-240-56.lightspeed.hstntx.sbcglobal.net
adsl-074-186-198-111.sip.bct.bellsouth.net
adsl-074-180-082-098.sip.msy.bellsouth.net
adsl-76-249-218-26.dsl.ipltin.sbcglobal.net
107-137-61-14.lightspeed.dctril.sbcglobal.net
ppp-69-154-243-168.dsl.snantx.swbell.net
76-247-209-6.lightspeed.irvnca.sbcglobal.net
adsl-67-115-147-49.dsl.snfc21.pacbell.net
adsl-64-219-48-157.dsl.stlsmo.swbell.net
99-7-157-170.lightspeed.livnmi.sbcglobal.net
108-95-81-179.lightspeed.irvnca.sbcglobal.net
adsl-074-187-096-042.sip.mia.bellsouth.net
99-3-151-36.lightspeed.miamfl.sbcglobal.net
adsl-098-072-119-014.sip.mia.bellsouth.net
23-119-110-98.lightspeed.gnvlsc.sbcglobal.net
adsl-65-64-193-184.dsl.rcsntx.swbell.net
99-39-130-215.lightspeed.iplsin.sbcglobal.net
162-202-218-108.lightspeed.rlghnc.sbcglobal.net
99-82-217-247.lightspeed.dybhfl.sbcglobal.net
108-73-111-58.lightspeed.bcvloh.sbcglobal.net
99-125-169-26.lightspeed.gnbonc.sbcglobal.net
adsl-66-137-37-63.dsl.tulsok.swbell.net
adsl-71-133-205-172.dsl.pltn13.pacbell.net
99-66-8-7.lightspeed.mssnks.sbcglobal.net
adsl-99-17-75-250.dsl.chi2ca.sbcglobal.net
99-123-26-217.lightspeed.nsvltn.sbcglobal.net
host37.bolgeracademy.com
45-20-74-119.lightspeed.spfdmo.sbcglobal.net
23-116-89-68.lightspeed.mssnks.sbcglobal.net
adsl-074-169-008-159.sip.mia.bellsouth.net
104-188-61-168.lightspeed.irvnca.sbcglobal.net
slip-12-66-244-199.mis.prserv.net
75-1-18-187.lightspeed.tukrga.sbcglobal.net
116.muda.miam.philapaaz.dsl.att.net
172-125-89-230.lightspeed.brhmal.sbcglobal.net
adsl-75-23-121-186.dsl.peoril.sbcglobal.net
adsl-107-197-5-93.dsl.rcsntx.sbcglobal.net
adsl-69-226-26-96.dsl.sndg02.pacbell.net
172-125-56-230.lightspeed.tukrga.sbcglobal.net
104-0-10-13.lightspeed.stlsmo.sbcglobal.net
adsl-67-118-83-114.dsl.lsan03.pacbell.net
adsl-99-115-186-10.dsl.hstntx.sbcglobal.net
adsl-098-072-086-096.sip.mia.bellsouth.net
104-11-105-142.lightspeed.hstntx.sbcglobal.net
108-224-59-147.lightspeed.rcsntx.sbcglobal.net
99-82-239-108.lightspeed.cicril.sbcglobal.net
23-126-109-148.lightspeed.hstntx.sbcglobal.net
69-213-68-146.ded.ameritech.net
adsl-75-53-206-203.dsl.hstntx.sbcglobal.net
162-239-29-95.lightspeed.dybhfl.sbcglobal.net
adsl-074-186-223-193.sip.bct.bellsouth.net
172-1-112-162.lightspeed.elpstx.sbcglobal.net
99-22-5-188.lightspeed.clmboh.sbcglobal.net
108-75-212-168.lightspeed.cicril.sbcglobal.net
54.sui173.chgc.cgcil31ur.dsl.att.net
76-216-185-243.lightspeed.cicril.sbcglobal.net
108-222-152-90.lightspeed.ltrkar.sbcglobal.net
adsl-75-48-201-70.dsl.chcgil.sbcglobal.net
68-123-14-154.lightspeed.sntcca.sbcglobal.net
69-239-58-41.lightspeed.tukrga.sbcglobal.net
dhcp-205-76.hq-san.attens.com
70-134-214-98.lightspeed.sndgca.sbcglobal.net
adsl-75-61-137-81.dsl.pltn13.sbcglobal.net
99-91-205-208.lightspeed.iplsin.sbcglobal.net
99-8-18-171.lightspeed.iplsin.sbcglobal.net
adsl-68-249-222-115.dsl.wotnoh.ameritech.net
99-68-158-36.lightspeed.rcsntx.sbcglobal.net
99-34-0-105.lightspeed.cntmoh.sbcglobal.net
172-9-122-124.lightspeed.tukrga.sbcglobal.net
99-96-74-164.lightspeed.gnvlsc.sbcglobal.net
adsl-76-221-124-92.dsl.sfldmi.sbcglobal.net
108-205-153-161.lightspeed.irvnca.sbcglobal.net
undefined.hostname.localhost
76-236-4-26.lightspeed.gdrpmi.sbcglobal.net
adsl-99-181-201-185.dsl.irvnca.sbcglobal.net
adsl-070-155-108-148.sip.asm.bellsouth.net
99-59-1-144.lightspeed.irvnca.sbcglobal.net
adsl-71-135-109-26.dsl.pltn13.pacbell.net
71-135-192-225.lightspeed.iplsin.sbcglobal.net
adsl-75-19-41-15.dsl.irvnca.sbcglobal.net
adsl-67-117-75-75.dsl.sntc01.pacbell.net
104-56-31-143.lightspeed.moblal.sbcglobal.net
adsl-69-211-245-134.dsl.chcgil.ameritech.net
adsl-76-224-128-176.dsl.rcsntx.sbcglobal.net
108-226-49-152.lightspeed.fyvlar.sbcglobal.net
99-20-58-112.lightspeed.crmlin.sbcglobal.net
176.muke.nyrk.nycenycp.dsl.att.net
107-195-187-243.lightspeed.frokca.sbcglobal.net
adsl-63-192-184-61.dsl.frsn01.pacbell.net
108-65-48-132.uvs.hstntx.sbcglobal.net
adsl-68-212-37-147.lft.bellsouth.net
99-68-150-38.lightspeed.irvnca.sbcglobal.net
68-251-144-226.lightspeed.dybhfl.sbcglobal.net
adsl-63-200-216-232.dsl.sndg02.pacbell.net
71-150-224-249.lightspeed.stlsmo.sbcglobal.net
107-221-227-48.lightspeed.iplsin.sbcglobal.net
75-46-147-123.lightspeed.irvnca.sbcglobal.net
75-9-81-77.lightspeed.milwwi.sbcglobal.net
108-254-28-39.lightspeed.dybhfl.sbcglobal.net
adsl-074-247-089-104.sip.bna.bellsouth.net
108-82-19-192.lightspeed.livnmi.sbcglobal.net
adsl-99-124-173-63.dsl.wlfrct.sbcglobal.net
slip129-37-10-94.fl.us.prserv.net
107-211-24-204.lightspeed.frsnca.sbcglobal.net
107-199-252-186.lightspeed.livnmi.sbcglobal.net
162-206-110-202.lightspeed.hstntx.sbcglobal.net
adsl-75-63-60-147.dsl.emhril.sbcglobal.net
118.st.louis-54-55rs.mo.dial-access.att.net
162-228-218-24.lightspeed.sntcca.sbcglobal.net
108-78-28-185.lightspeed.livnmi.sbcglobal.net
104-8-43-208.lightspeed.mmphtn.sbcglobal.net
104-12-237-105.lightspeed.jcsnms.sbcglobal.net
adsl-70-140-203-113.dsl.chcgil.sbcglobal.net
adsl-76-200-247-40.dsl.scrm01.sbcglobal.net
108-64-178-109.lightspeed.livnmi.sbcglobal.net
107-221-114-170.lightspeed.clmasc.sbcglobal.net
23-118-156-239.lightspeed.irvnca.sbcglobal.net
adsl-098-075-035-065.sip.gsp.bellsouth.net
45-21-186-23.lightspeed.btrgla.sbcglobal.net
70-132-252-81.lightspeed.miamfl.sbcglobal.net
107-138-255-235.lightspeed.stlsmo.sbcglobal.net
108-255-26-13.lightspeed.cicril.sbcglobal.net
104-10-65-46.lightspeed.mssnks.sbcglobal.net
99-155-58-218.lightspeed.dllstx.sbcglobal.net
108-90-183-132.lightspeed.chrlnc.sbcglobal.net
99-115-33-11.lightspeed.miamfl.sbcglobal.net
99-10-176-173.lightspeed.wepbfl.sbcglobal.net
adsl-67-33-186-168.asm.bellsouth.net
104-185-119-165.lightspeed.iplsin.sbcglobal.net
adsl-107-203-140-7.dsl.hrlntx.sbcglobal.net
99-167-220-143.lightspeed.tulsok.sbcglobal.net
adsl-71-129-245-186.dsl.pltn13.pacbell.net
45-21-122-7.lightspeed.mmphtn.sbcglobal.net
adsl-76-254-7-11.dsl.pltn13.sbcglobal.net
107-139-108-63.lightspeed.irvnca.sbcglobal.net
109.muf38.phla.plapa01r1.dsl.att.net
adsl-64-171-74-128.dsl.lsan03.pacbell.net
162-202-187-138.lightspeed.nworla.sbcglobal.net
adsl-67-124-218-216.dsl.sndg02.pacbell.net
adsl-75-32-124-114.dsl.pltn13.sbcglobal.net
99-141-136-166.lightspeed.jcsnms.sbcglobal.net
108-74-156-227.lightspeed.cicril.sbcglobal.net
adsl-75-11-74-134.dsl.chcgil.sbcglobal.net
99-0-111-139.lightspeed.dybhfl.sbcglobal.net
107-135-17-130.lightspeed.nworla.sbcglobal.net
adsl-67-112-167-37.dsl.lsan03.pacbell.net
107-205-36-227.lightspeed.clmboh.sbcglobal.net
isdn-208-189-185-223.ded.swbell.net
70-232-6-62.ded.ameritech.net
75-52-83-125.lightspeed.tukrga.sbcglobal.net
76-218-75-12.lightspeed.irvnca.sbcglobal.net
adsl-99-23-221-106.dsl.sgnwmi.sbcglobal.net
adsl-172-3-91-243.stl2mo.sbcglobal.net
104-177-169-228.lightspeed.bcvloh.sbcglobal.net
76-232-88-162.lightspeed.rcsntx.sbcglobal.net
adsl-69-223-118-207.dsl.milwwi.ameritech.net
108-253-196-244.lightspeed.rcsntx.sbcglobal.net
adsl-68-252-87-39.dsl.sfldmi.ameritech.net`
        }
    ];
    const outputs = [
        {
            title: `PTR Record Groupings Generated by The Aleph for AT\&T (AS7018)`,
            content: `{
    "SBCGlobal_Lightspeed_GeographicEncoding": {
        "examples": [
            "99-170-164-205.lightspeed.tukrga.sbcglobal.net",
            "107-194-77-243.lightspeed.austtx.sbcglobal.net",
            "162-193-172-90.lightspeed.miamfl.sbcglobal.net",
            "104-183-175-2.lightspeed.okcbok.sbcglobal.net",
            "104-187-221-175.lightspeed.toldoh.sbcglobal.net"
        ],
        "description": "In the SBCGlobal_Lightspeed_GeographicEncoding class, the geographic information is encoded in the subdomain immediately preceding 'sbcglobal.net' and following 'lightspeed'. This subdomain typically consists of a city and state abbreviation, concatenated together. The city is represented by a sequence of lowercase letters, and the state abbreviation consists of exactly two lowercase letters. These are separated by a period from the IP-like numeric sequence and 'lightspeed'. For example, in '104-187-221-175.lightspeed.toldoh.sbcglobal.net', 'toldoh' represents Toledo, Ohio.",
        "usable": true
    },
    "SBCGlobal_ADSL_GeographicEncoding": {
        "examples": [
            "adsl-99-163-197-39.dsl.wac2tx.sbcglobal.net",
            "adsl-208-191-10-108.dsl.okcyok.swbell.net",
            "adsl-99-75-30-16.dsl.rcfril.sbcglobal.net",
            "adsl-99-36-211-162.dsl.skt2ca.sbcglobal.net",
            "adsl-99-152-50-15.dsl.elp2tx.sbcglobal.net"
        ],
        "description": "In the provided RDNS records, the geographic information is encoded in the segment immediately following 'dsl.' in the domain name. This segment typically consists of a three to five-letter abbreviation that represents a city or region, followed by a two-letter state abbreviation. The segments are separated by a period and the entire geographic hint is followed by the ISP domain. The pattern includes lowercase letters and numbers, and the geographic hint is specifically in lowercase letters separated by a period from other parts of the domain.",
        "usable": true
    },
    "BellSouth_ADSL_GeographicEncoding": {
        "examples": [
            "adsl-074-237-084-231.sip.mia.bellsouth.net",
            "adsl-072-151-067-111.sip.clt.bellsouth.net",
            "adsl-072-149-062-224.sip.bct.bellsouth.net",
            "adsl-070-154-238-080.sip.dab.bellsouth.net",
            "adsl-074-236-001-094.sip.mia.bellsouth.net"
        ],
        "description": "In the BellSouth_ADSL_GeographicEncoding class, the geographic information is encoded as a three-letter abbreviation immediately following the 'sip.' segment in the RDNS record. These abbreviations represent cities or regions. The pattern consists of the prefix 'adsl-', followed by a series of four groups of three digits each, separated by hyphens. After the IP-like sequence, the string '.sip.' appears, followed by the three-letter geographic abbreviation, and ending with '.bellsouth.net'.",
        "usable": true
    },
    "PacBell_ADSL_GeographicEncoding": {
        "examples": [
            "adsl-67-122-3-2.dsl.lsan03.pacbell.net",
            "ppp-71-139-168-6.dsl.snfc21.pacbell.net",
            "adsl-71-128-154-120.dsl.scrm01.pacbell.net",
            "adsl-69-210-207-232.dsl.klmzmi.ameritech.net",
            "adsl-69-219-222-66.dsl.chcgil.ameritech.net"
        ],
        "description": "In the provided RDNS records, the geographic information is encoded in a segment that appears right before the domain 'pacbell.net' or 'ameritech.net'. This segment is typically structured as 'dsl.CITYCODE', where 'CITYCODE' is a combination of letters indicating the city or region, followed by a two-letter state code in the United States. The city and state code are concatenated without any delimiter. The segment is preceded by an IP address-like sequence and followed by the domain name.",
        "usable": true
    },
    "Ameritech_ADSL_GeographicEncoding": {
        "examples": [
            "adsl-69-210-207-232.dsl.klmzmi.ameritech.net",
            "adsl-69-219-222-66.dsl.chcgil.ameritech.net",
            "adsl-68-75-21-194.dsl.wotnoh.ameritech.net",
            "adsl-68-248-114-224.dsl.ipltin.ameritech.net",
            "adsl-70-228-33-172.dsl.chcgil.ameritech.net"
        ],
        "description": "In the Ameritech_ADSL_GeographicEncoding class, geographic information is encoded in a segment of the RDNS record that appears immediately after 'dsl.' and before the next dot ('.'). This segment typically consists of a combination of letters, which are abbreviations or codes representing cities or regions, followed by a two-letter state code. The format is 'citystate', where 'city' is a variable-length abbreviation of the city name and 'state' is a fixed two-letter state code. The entire geographic code is lowercase and separated from other parts of the domain by dots.",
        "usable": true
    },
    "Mobile_Cingular": {
        "examples": [
            "mobile-166-198-164-023.mycingular.net",
            "mobile-166-199-079-114.mycingular.net",
            "mobile-107-95-19-13.mycingular.net",
            "mobile-107-94-173-172.mycingular.net",
            "mobile-166-205-194-003.mycingular.net"
        ],
        "description": "The RDNS records in the Mobile_Cingular class do not contain explicit geographic information such as city, state, region, or country. The records follow a pattern where they start with the word 'mobile' followed by a series of four numeric blocks separated by hyphens, and ending with '.mycingular.net'. The numeric blocks appear to represent an IP address, but there is no clear mapping of these numbers to specific geographic locations within the record format itself.",
        "usable": false
    },
    "Miscellaneous_Domains": {
        "examples": [
            "ip-12-230-195-CA.aradigm.com",
            "mail.mashekco.com",
            "fullsailalum.com",
            "windsonginc.com",
            "nat220.rainier.com"
        ],
        "description": "In the Miscellaneous_Domains class, the geographic information appears to be encoded in the subdomain section of the RDNS record, specifically using state abbreviations. The pattern observed is that the state abbreviation (two uppercase letters) is placed after a numeric IP-like prefix and a hyphen. The state abbreviation is followed by another hyphen. This pattern is not consistent across all records in the class, as some records do not contain any geographic hints.",
        "usable": true
    }
}`
        },
        {
            title: `Regular Expressions Generated By The Aleph`,
            content: `  "SBCGlobal_Lightspeed_GeographicEncoding": {
    "regex": "(?<=lightspeed\\.)[a-z]+[a-z]{2}(?=\\.sbcglobal\\.net)"
  },
  "SBCGlobal_ADSL_GeographicEncoding": {
    "regex": "dsl\\.([a-z0-9]+)\\.[a-z]{2}",
  },
  "BellSouth_ADSL_GeographicEncoding": {
    "regex": "sip\\.(\\w{3})\\.bellsouth\\.net",
  },
  "PacBell_ADSL_GeographicEncoding": {
    "regex": "(?<=dsl\\.)[a-z]+[a-z]{2}(?=\\.pacbell\\.net|\\.ameritech\\.net)",
  },
  "Ameritech_ADSL_GeographicEncoding": {
    "regex": "dsl\\.([a-z]+[a-z]{2})\\.ameritech\\.net",
  },
  "Mobile_Cingular": {
    "regex": "",
  },
  "Miscellaneous_Domains": {
    "regex": "ip-\\d+-\\d+-(\\w{2})\\.",
  }`
        },
        {
            title: `Hint Mapping Generated By The Aleph For AT\&T (AS7018)`,
            content: `{
    "tukrga": {
        "city": "Tucker",
        "state": "GA",
        "region": "",
        "country": "US"
    },
    "austtx": {
        "city": "Austin",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "wac2tx": {
        "city": "Waco",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "okcyok": {
        "city": "Oklahoma City",
        "state": "OK",
        "region": "",
        "country": "US"
    },
    "rcfril": {
        "city": "Rockford",
        "state": "IL",
        "region": "",
        "country": "US"
    },
    "skt2ca": {
        "city": "Stockton",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "miamfl": {
        "city": "Miami",
        "state": "FL",
        "region": "",
        "country": "US"
    },
    "okcbok": {
        "city": "Oklahoma City",
        "state": "OK",
        "region": "",
        "country": "US"
    },
    "toldoh": {
        "city": "Toledo",
        "state": "OH",
        "region": "",
        "country": "US"
    },
    "irvnca": {
        "city": "Irvine",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "sntcca": {
        "city": "Santa Clara",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "elp2tx": {
        "city": "El Paso",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "mia": {
        "city": "Miami",
        "state": "FL",
        "region": "",
        "country": "US"
    },
    "rcsntx": {
        "city": "Richardson",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "stl2mo": {
        "city": "St. Louis",
        "state": "MO",
        "region": "",
        "country": "US"
    },
    "hstntx": {
        "city": "Houston",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "clmboh": {
        "city": "Columbus",
        "state": "OH",
        "region": "",
        "country": "US"
    },
    "sndgca": {
        "city": "San Diego",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "cicril": {
        "city": "Cicero",
        "state": "IL",
        "region": "",
        "country": "US"
    },
    "snantx": {
        "city": "San Antonio",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "dctril": {
        "city": "Decatur",
        "state": "IL",
        "region": "",
        "country": "US"
    },
    "btrgla": {
        "city": "Baton Rouge",
        "state": "LA",
        "region": "",
        "country": "US"
    },
    "rlghnc": {
        "city": "Raleigh",
        "state": "NC",
        "region": "",
        "country": "US"
    },
    "frsnca": {
        "city": "Fresno",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "lsan03": {
        "city": "Los Angeles",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "wlfrct": {
        "city": "Wolcott",
        "state": "CT",
        "region": "",
        "country": "US"
    },
    "pltn13": {
        "city": "Pleasanton",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "frokca": {
        "city": "Fresno",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "snfc21": {
        "city": "San Francisco",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "scrm01": {
        "city": "Sacramento",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "milwwi": {
        "city": "Milwaukee",
        "state": "WI",
        "region": "",
        "country": "US"
    },
    "stlsmo": {
        "city": "St. Louis",
        "state": "MO",
        "region": "",
        "country": "US"
    },
    "sndg02": {
        "city": "San Diego",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "klmzmi": {
        "city": "Kalamazoo",
        "state": "MI",
        "region": "",
        "country": "US"
    },
    "ipltin": {
        "city": "Indianapolis",
        "state": "IN",
        "region": "",
        "country": "US"
    },
    "chcgil": {
        "city": "Chicago",
        "state": "IL",
        "region": "",
        "country": "US"
    },
    "mmphtn": {
        "city": "Memphis",
        "state": "TN",
        "region": "",
        "country": "US"
    },
    "nsvltn": {
        "city": "Nashville",
        "state": "TN",
        "region": "",
        "country": "US"
    },
    "oshkwi": {
        "city": "Oshkosh",
        "state": "WI",
        "region": "",
        "country": "US"
    },
    "ftwotx": {
        "city": "Fort Worth",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "tulsok": {
        "city": "Tulsa",
        "state": "OK",
        "region": "",
        "country": "US"
    },
    "mssnks": {
        "city": "Mission",
        "state": "KS",
        "region": "",
        "country": "US"
    },
    "dybhfl": {
        "city": "Daytona Beach",
        "state": "FL",
        "region": "",
        "country": "US"
    },
    "wepbfl": {
        "city": "West Palm Beach",
        "state": "FL",
        "region": "",
        "country": "US"
    },
    "jcsnms": {
        "city": "Jackson",
        "state": "MS",
        "region": "",
        "country": "US"
    },
    "moblal": {
        "city": "Mobile",
        "state": "AL",
        "region": "",
        "country": "US"
    },
    "brhmal": {
        "city": "Birmingham",
        "state": "AL",
        "region": "",
        "country": "US"
    },
    "ltrkar": {
        "city": "Little Rock",
        "state": "AR",
        "region": "",
        "country": "US"
    },
    "evtnil": {
        "city": "Evanston",
        "state": "IL",
        "region": "",
        "country": "US"
    },
    "bcvloh": {
        "city": "Beachwood",
        "state": "OH",
        "region": "",
        "country": "US"
    },
    "gnvlsc": {
        "city": "Greenville",
        "state": "SC",
        "region": "",
        "country": "US"
    },
    "lbcktx": {
        "city": "Lubbock",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "lnngmi": {
        "city": "Lansing",
        "state": "MI",
        "region": "",
        "country": "US"
    },
    "bkfdca": {
        "city": "Bakersfield",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "jcvlfl": {
        "city": "Jacksonville",
        "state": "FL",
        "region": "",
        "country": "US"
    },
    "knvltn": {
        "city": "Knoxville",
        "state": "TN",
        "region": "",
        "country": "US"
    },
    "cntmoh": {
        "city": "Canton",
        "state": "OH",
        "region": "",
        "country": "US"
    },
    "gdrpmi": {
        "city": "Grand Rapids",
        "state": "MI",
        "region": "",
        "country": "US"
    },
    "wchtks": {
        "city": "Wichita",
        "state": "KS",
        "region": "",
        "country": "US"
    },
    "mdsnwi": {
        "city": "Madison",
        "state": "WI",
        "region": "",
        "country": "US"
    },
    "wcfltx": {
        "city": "Wichita Falls",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "fyvlar": {
        "city": "Fayetteville",
        "state": "AR",
        "region": "",
        "country": "US"
    },
    "applwi": {
        "city": "Appleton",
        "state": "WI",
        "region": "",
        "country": "US"
    },
    "spfdmo": {
        "city": "Springfield",
        "state": "MO",
        "region": "",
        "country": "US"
    },
    "hrlntx": {
        "city": "Harlingen",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "crchtx": {
        "city": "Corpus Christi",
        "state": "TX",
        "region": "",
        "country": "US"
    },
    "dytnoh": {
        "city": "Dayton",
        "state": "OH",
        "region": "",
        "country": "US"
    },
    "yntwoh": {
        "city": "Youngstown",
        "state": "OH",
        "region": "",
        "country": "US"
    },
    "mtry01": {
        "city": "Monterey",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "renonv": {
        "city": "Reno",
        "state": "NV",
        "region": "",
        "country": "US"
    },
    "sktnca": {
        "city": "Stockton",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "artnca": {
        "city": "Arlington",
        "state": "CA",
        "region": "",
        "country": "US"
    },
    "burlnc": {
        "city": "Burlington",
        "state": "North Carolina",
        "country": "USA",
        "region": ""
    },
    "livnmi": {
        "city": "Livonia",
        "state": "Michigan",
        "country": "USA",
        "region": ""
    },
    "cmpkmi": {
        "city": "Comstock Park",
        "state": "Michigan",
        "country": "USA",
        "region": ""
    },
    "iplsin": {
        "city": "Indianapolis",
        "state": "Indiana",
        "country": "USA",
        "region": ""
    },
    "chrlnc": {
        "city": "Charlotte",
        "state": "North Carolina",
        "country": "USA",
        "region": ""
    },
    "chtnsc": {
        "city": "Chattanooga",
        "state": "Tennessee",
        "country": "USA",
        "region": ""
    },
    "sprious": {
        "city": "Unknown",
        "state": "Unknown",
        "country": "USA",
        "region": ""
    },
    "fresno": {
        "city": "Fresno",
        "state": "California",
        "country": "USA",
        "region": ""
    }
}`
        }
    ];

    const renderGrouping = () => {
        return (
            <div className="container scaled-container centered-container space-above-container space-below-container">

                In this step, we tune the LLM to group DNS PTR records based on their TLD and how geographic information is encoded within them. The LLM is provided
                the instructional prompt, list of records and produces a grouping of records with examples.

                <div className="scrollbox">
                    <div className="code-header">{prompts[1].title}</div>
                    <div className="code-content">{prompts[1].content}</div>
                </div>
                <div className="scrollbox">
                    <div className="code-header">PTR Records From AT&T Used Classification</div>
                    <div className="code-content">{inputs[0].content}</div>
                </div>

                The PTR sample for grouping is formed by taking a random subset from the total set of PTR records managed by an AS. Our prompting strategies ensure
                that output is formatted such that it can be immediately used by the Aleph in the next step with no human intervention.

                <div className="scrollbox">
                    <div className="code-header">{outputs[0].title}</div>
                    <div className="code-content">{outputs[0].content}</div>
                </div>
            </div>
        );
    };

    const renderRegex = () => {
        return(
            <div className='container scaled-container centered-container space-above-container space-below-container'>
                In this step, we use groupings created in the first step to create tailored prompts for regex generation using Few-Shot Learning (FSL). For each grouping, the LLM is provided with instructions for crafting regular expressions and example records belonging to that group.

                <div className="scrollbox">
                    <div className="code-header">{prompts[0].title}</div>
                    <div className="code-content">{prompts[0].content}</div>
                </div>

                The user prompt is created dynamically based on the groupings generated by the Aleph in the previous step. The prompt above would be extended with information about a class of records shown in the box below.

                <div className="scrollbox">
                    <div className="code-header">{inputs[1].title}</div>
                    <div className="code-content">{inputs[1].content}</div>
                </div>

                We do this once per grouping generated by the Aleph for an Autonomous System. The box below showcases the regular expressions generated by the Aleph for AT&T.
                <div className="scrollbox">
                    <div className="code-header">{outputs[1].title}</div>
                    <div className="code-content">{outputs[1].content}</div>
                </div>

            </div>
        )
    }

    const renderMapping = () => {
        return (
            <div className='container scaled-container centered-container space-above-container space-below-container'>
                In this step, we use regular expressions generated by the Aleph to find records that may contain geographic hints. An example of a sample of records for hint mapping is presented below.

                <div className="scrollbox">
                    <div className="code-header">{inputs[2].title}</div>
                    <div className="code-content">{inputs[2].content}</div>
                </div>

                We then use a prompt to tune the LLM to create a structured mapping of hints to locations.

                <div className="scrollbox">
                    <div className="code-header">{prompts[2].title}</div>
                    <div className="code-content">{prompts[2].content}</div>
                </div>

                <div className="scrollbox">
                    <div className="code-header">{outputs[2].title}</div>
                    <div className="code-content">{outputs[2].content}</div>
                </div> 

            </div>
        )
    }

    return (
        <div>
            <Navbar />

            <div style={{ fontSize: '3em', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>
                <i>The Aleph (ℵ)</i> : System Design
            </div>
            
            <div className='centered-container div-border space-above-container space-bottom-container big-bold-text title-scale'>
                We divide the process of generating regular expressions and hint mappings into 3 steps: Grouping, Regex Generation, and Hint Mapping. Each step requires training the LLM with a specific prompt to generate output in the desired format. Outputs from each step are directly parsed and used to create input for the subsequent steps. Click on the buttons below the diagram for more details on prompts and outputs of the Aleph with examples using PTR records from AT&T.
            </div>
            <div className='centered-container space-above-container scaled-container-img'><img className='scaled-image-container' src='system-diagram.png'></img></div>
            <div className="button-container-demo centered-container">
                <button className='gray-background big-bold-text div-border' onClick={() => setActiveView('grouping')}>Grouping (Prompt 1)</button>
                <button className='light-blue-background big-bold-text div-border' onClick={() => setActiveView('regex')}>Regex Generation (Prompt 2)</button>
                <button className='slightly-darker-light-blue-background big-bold-text div-border' onClick={() => setActiveView('mapping')}>Hint Mapping (Prompt 3)</button>
            </div>
            <div style={{ width: '100%', height: '2px', backgroundColor: '#000', margin: '20px 0' }}></div>
            {activeView === 'grouping' && renderGrouping()}
            {activeView === 'regex' && renderRegex()}
            {activeView === 'mapping' && renderMapping()}
        </div>
    );
}

export default Demo;
